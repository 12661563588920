import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Heading from "react-bulma-components/lib/components/heading/heading";
import { cn } from "reusable-components/helper";

import { getArticleById, getArticleUrl } from "../../../../helper";
import PublishDate from "../../publish-date";
import Styles from "./index.module.scss";


const ArticleTile = ({ articleId, isBrandNew = false, isMinimalistic = false, className }) => {
	const query = useStaticQuery(graphql`
        query {
            takeshape {
                getBlogartikelList {
                    items {
                        id: _id
                        updated: _updatedAt
	                    
                        title
                        description
                        linkedArticles {
                            id: _id
                            title
                        }
                    }
                }
            }
        }
	`);

	const article = getArticleById(articleId, query.takeshape.getBlogartikelList.items);
	const {
		updated,
		title,
	} = article;

	if (!article) {
		throw `Couldn’t found article by id ${ articleId }`;
	}

	return (
		<a
			className={ cn(
				className,
				Styles.tile,
				isBrandNew && Styles.isBrandNew,
				isMinimalistic && Styles.isMinimalistic,
			) }
			href={ getArticleUrl(title) }>

			{ isBrandNew &&
				<div className={ Styles.brandNewStripe }>
					<span>Brand Neu</span>
				</div>
			}

			<PublishDate
				textAlignment={ "left" }
				date={ updated }
				className={ Styles.updatedTime }
			/>

			<Heading
				textSize={ isMinimalistic ? 5 : 3 }
				renderAs={ "h2" }
				className={ Styles.heading }>
				{ title }
			</Heading>

		</a>
	);
};

export default ArticleTile;