import React from "react";
import Heading from "react-bulma-components/lib/components/heading/heading";
import { cn } from "reusable-components/helper";

import Styles from "./index.module.scss";


const PublishDate = ({ date, className, ...props }) => {
	const parsedDate = new Date(date);
	const dMY = `${ parsedDate.getDate() } / ${ parsedDate.getMonth() } / ${ parsedDate.getFullYear().toString().substr(-2) }`;
	const time = `${ parsedDate.getHours() }:${ parsedDate.getMinutes() } Uhr`;

	return (
		<Heading
			renderAs={ "span" }
			subtitle
			textAlignment={ "centered" }
			className={ cn(Styles.updatedTime, className) }
			{ ...props }>
			{ dMY } · { time }
		</Heading>
	);
};

export default PublishDate;