import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Columns from "react-bulma-components/lib/components/columns/columns"
import Column from "react-bulma-components/lib/components/columns/components/column"
import Container from "react-bulma-components/lib/components/container/container"
import Content from "react-bulma-components/lib/components/content/content"

import Heading from "react-bulma-components/lib/components/heading/heading"
import { cn } from "reusable-components/helper"

import HorizontalDivider from "../../ui/divider/horizontal"
import Reveal from "../../ui/reveal"
import Section from "../generic"
import Styles from "./index.module.scss"


const Footer = props => {
	const query = useStaticQuery(graphql`
		query {
			takeshape {
				getContact {
					name
					street
					streetNumber
					zipCode
					city
					mail
					mobile
					phone
					fax
				}

				getpartnersList {
					items {
						name
						link
						logo {
							path
						}
					}
				}

				getFooter {
					quote
					quoteAuthor

					items {
						links {
							link {
								title
								url
							}
						}
						title
					}
				}
			}
		}
	`)

	const {
		name,
		street,
		streetNumber,
		zipCode,
		city,
		mail,
		phone,
		mobile,
		fax
	} = query.takeshape.getContact

	// footer
	const {
		items,
		quote,
		quoteAuthor,
	} = query.takeshape.getFooter

	const { getpartnersList } = query.takeshape

	return (
		<Section { ...props }>
			<Container>
				<Reveal>
					<div className={ Styles.apostrophe } />

					<blockquote className={ cn("is-size-3", Styles.quote) }>
						<p>{ quote }</p>

						<p className={ "subtitle has-text-centered mt-3" }>{ quoteAuthor }</p>
					</blockquote>

					<HorizontalDivider className={ Styles.divider } />

					<Columns className={ Styles.footerItems }>
						{ items.map(({ title, links }) =>
							<FooterColumn
								key={ title }
								title={ title }
								links={ links.map(item => item.link) }
							/>
						) }

						<FooterColumn
							key={ "partners" }
							title={ "Kooperations-Partner" }
							links={ getpartnersList.items.map(p => ({
								title: p.name,
								url: p.link
							})) }
						/>

						<FooterColumn
							key={ "address" }
							title={ "Anschrift" }
						>

							{ name } <br />
							{ street } { streetNumber } <br />
							{ zipCode } { city }

						</FooterColumn>

						<FooterColumn
							key={ "contact" }
							title={ "Kontakt" }
						>
							{ mail && (
								<>
									Mail · <a href={ `mailto:${ mail }` }>{ mail }</a><br />

								</>
							)}

							{ phone && (
								<>
									Fon · <a href={ `tel:${ phone }` }>{ phone }</a><br />
								</>
							)}

							{ mobile && (
								<>
									Mobil · <a href={ `tel:${ mobile }` }>{ mobile }</a><br />
								</>
							)}

							{ fax && (
								<>
									Fax · <a href={ `tel:${ fax }` }>{ fax }</a>
								</>
							)}
						</FooterColumn>
					</Columns>
				</Reveal>
			</Container>
		</Section>
	)
}

const FooterColumn = ({ title, links, children }) =>
	<Column narrow>
		<Heading
			renderAs={ "h4" }
			textSize={ 5 }
			className={ "has-margin-bottom-md" }
		>
			{ title }
		</Heading>

		{ children ?

			<Content
				textSize={ 6 }
				className={ Styles.content }
			>
				{ children }
			</Content> :

			<ul className={ "content is-size-6" }>
				{ links.map(({ title, url }) =>
					<li
						className={ Styles.listItem }
						key={ title }
					>

						<a
							target={ "_parent" }
							rel={ "noopener noreferrer" }
							href={ url }
						>
							{ title }
						</a>

					</li>
				) }
			</ul>

		}
	</Column>

export default Footer
