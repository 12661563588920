import React from "react";
import RevealComp from "react-reveal/Reveal";

import Styles from "./index.module.scss";


const Reveal = ({ children }) =>
	<RevealComp effect={ Styles.reveal } cascade>
		{ children }
	</RevealComp>;

export default Reveal;