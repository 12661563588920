const slugify = require("slugify");

const SCROLL_OFFSET = 75;


const scrollToContact = () => {
	const selector = `#contact`;
	const target = document.querySelector(selector);

	if (!target) {
		console.error(`Couldn’t find target element with selector «${ selector }» to scroll to`);
		return;
	}

	const targetY = target.getBoundingClientRect().top;
	const scrollPos = document.documentElement.scrollTop;

	const newScrollPos = targetY + SCROLL_OFFSET + scrollPos;

	window.scrollTo({
		top: newScrollPos,
		behavior: "smooth"
	});
}

const getArticleById = (articleId, articleList) => {
	for (const article of articleList) {
		if (article.id === articleId) {
			return article
		}
	}

	return null;
};

const getArticleUrl = title => {
	return `/blog/${ slugify(title.toLowerCase()) }`;
};


module.exports = {
	scrollToContact,
	getArticleById,
	getArticleUrl
};