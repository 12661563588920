import React from "react";
import Reveal from "../../ui/reveal";

import { cn } from "reusable-components/helper";
import SectionComp from "react-bulma-components/lib/components/section/section";

import Styles from "./index.module.scss";
import SEPARATOR_SRC from "../../../assets/sections/generic/separator.svg";


const Section = ({ id, isDark, hasSeparatorTop, hasSeparatorBottom, children, ...props }) =>
	<div id={ id }>
		{ hasSeparatorTop &&
			<img
				src={ SEPARATOR_SRC }
				alt=""
				className={ Styles.separator }
			/>
		}

		<SectionComp
			{ ...props }
			className={ cn(Styles.section, isDark && Styles.isDark) }>

			<Reveal>
				{ children }
			</Reveal>

		</SectionComp>

		{ hasSeparatorBottom &&
			<img
				src={ SEPARATOR_SRC }
				alt=""
				className={ cn(Styles.separator, Styles.uspideDown) }
			/>
		}
	</div>;

export default Section;